@mixin pageHeader {
    font-family: Montserrat;
    font-style: normal;
    font-weight: 600;
    font-size: 48px;
    letter-spacing: -0.06em;
    color: #000000;
    transform: translateY(-10%);
}


@mixin activeCoreText{
    font-family: Montserrat;
    font-size: 30px;
    font-style: normal;
    font-weight: 400;
    letter-spacing: -0.06em;
    text-align: left;
    color: #000000;
    margin: 10px;
}


@mixin econText{
    // font-family: Ubuntu;
    font-style: normal;
    font-weight: bold;
    color: #000000;
}