.apply-button{
    font-size:large;
    border-radius:5px; 
    padding-left: 10px; 
    padding-right: 10px; 
    background:white; 
    color: #702632;
}
.loading-logo{
    margin-left: 20px;
    padding: 0;
    padding-top: 10px;
    max-height: 100px;
    max-width: 200px;
}
.content{
    background-color: #F9F8F7;
    align-items: center;
}
.AppBar {
    width: calc(100% - 240px);
    margin-left: 240px;
    display: block;
    color: #fff;
    position: fixed;
}
/* .title {
    flex-grow: 1;
    font-size: x-large;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    margin: 0px;
} */
.burger {
    outline: none !important;
    margin-right: 16px;
    margin-left: -12px;
    cursor: pointer;
    padding: 12px;
    background: transparent;
    border: none;
    color: white;
}
@media (min-width: 600px) {
    .drawer{
        width: 240px;
        flex-shrink: 0;
    }
    .content {
    padding-left: 240px;
    }
    .AppBar {
        display: none!important;
    }
    /* .title {
        margin-left: 240px!important;
    } */
    .burger {
        display: none;
    }
}
.dontgrow{
    display: flex;
    justify-content: space-between;
}