.logo{
    max-width: 230px;
    padding-top: 5%;
    margin-left: 2%;
    margin-top: auto;
}
.toplogo{
    max-width: 230px;
}
.main-div{
    padding: 24px;
    text-align: center;
}
.toolbar {
    padding-top: 4%;
    min-height: 64px;
    max-width: 400px;
    display: flex;
    justify-content: center;
}
.root {
    width:100%;
    box-shadow: none;
    text-align: center;
    background-color:#F9F8F7;
}
.maxwidth-center{
    width:95%;
    max-width: 1000px;
    text-align: center;
    margin: auto;
}
.our-paper{
    box-shadow: 0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%);;
    border-radius: 22px!important;
    padding: 10px;
    margin: 20px;
    margin-top: 40px!important;
    background-color:rgba(221, 221, 221, 1);
}
.container {
    max-width: 1000px;
    min-width: 50%;
    text-align: left;
    margin-left: auto;
    margin-right: auto;
    justify-content: left;
    background-color: rgba(221, 221, 221, 1);
}
@media (max-width: 479px) { 
    .container { 
        padding-right: 0px;
    padding-left: 0px;
    }
    .our-paper{
        margin: 0px;
        }
    .main-div{
        padding: 0px;
        padding-top: 70px!important;
    }
}

.bgcolor{
    background-color:rgba(221, 221, 221, 1) !important;
}

.box::-webkit-scrollbar{
    /* Hide scrollbar for Chrome, Safari and Opera */
display: none;
/* Hide scrollbar for IE, Edge and Firefox */
-ms-overflow-style: none;  /* IE and Edge */
scrollbar-width: none;  /* Firefox */
}