@use "../../stylesheet/elements";
@use "../../stylesheet/textStyle";
@use "../../stylesheet/paging";

.activeCoreTab {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  width: 240px;
  background: #dddddd;
  height: 100vh;
  overflow-y: hidden;
  @media screen and (max-width: 640px) {
    width: 100%;
    overflow-y: auto;
  }

  .topTab {
    color: white;
    height: 100px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: url("../../assets/loginTopBackground.png"), #00000091;
    background-blend-mode: overlay;
    background-size: 280px 500px;
    background-position: 0px -50px;
    @media screen and (max-width: 640px) {
      height: 0%;
      display: none;
    }

    .clickArrow {
      display: none;
      @media screen and (max-width: 640px) {
        display: flex;
        padding: 10px;
        button {
          width: 65px;
          height: 32px;
          border-radius: 10px;
          border: 1px solid #00000080;
          box-shadow: 0px 1px 4px 0px #00000040;

          img {
            width: 19px;
            height: 18px;
            opacity: 0.4;
          }
        }
      }
    }

    .webTitle {
      display: flex;
      flex-direction: column;
      justify-content: center;

      @media screen and (max-width: 640px) {
        padding: 10px;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
      }
      img {
        width: 200px;
        height: 48px;
        @media screen and (max-width: 640px) {
          width: 250px;
          height: 55px;
        }
      }

      h1 {
        margin: 0px;
        font-family: Montserrat;
        font-size: 6px;
        font-style: normal;
        font-weight: 500;
        // line-height: 35px;
        letter-spacing: 1.98em;
        text-align: center;
        color: #ffffff;
        transform: translateY(-60%);
        @media screen and (max-width: 640px) {
          line-height: 10px;
        }
      }
    }
  } //end of topTab

  .contentTab {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 89vh;
    @media screen and (max-width: 640px) {
      height: 100vh;
    }

    .midTab {
      .userTab {
        @include elements.userTile();
        display: flex;
        justify-content: flex-start;
        align-items: center;
        gap: 10px;
        margin: 20px 5px;

        .userImage {
          padding-left: 10px;
          img {
            border-radius: 50%;
            width: 35px;
            height: 35px;
          }
        }

        .userDescription {
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
          gap: 0px;
          align-items: center;
          width: 100%;
          .userName {
            font-family: Montserrat;
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            text-align: left;
            letter-spacing: 0em;
          }

          .email {
            font-family: Montserrat;
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            text-align: left;
            color: #00000099;
            letter-spacing: 0em;
          }
        }
      } //end of userTab

      .tabOptions {
        margin-top: 40px;

        div {
          height: 60px;
          button {
            padding: 0px 5px;
            background: none;
            border: none;
            width: 100%;
            height: 100%;
            text-align: start;
            .tabTitle {
              @include textStyle.activeCoreText();
            }
          }
        }

        .active {
          @include elements.activeCoreTab();
        }
      } //end of tabOptions
    }

    .bottomTab {
      text-align: center;
      .dateInfo {
        padding: 10px 0px;
        p {
          margin: 5px;
          font-weight: 400;
          font-family: Montserrat;
          font-size: 18px;
          font-style: normal;
          font-weight: 400;
          letter-spacing: 0em;
        }
      }
      .logoutButton {
        margin-bottom: 25px;

        button {
          background: none;
          border: none;
          font-weight: 300;
          font-size: 14px;
        }
      }
    }
  }
}

////////////// SIDE-BAR //////////////////
.AppBar {
  padding-top: 5px;
  height: 80px;
  background: url("../../assets/loginTopBackground.png"), #00000091 !important;
  background-blend-mode: overlay !important;
  background-size: 450px 500px !important;
  background-position: 0px -50px !important;

  .MuiToolbar-gutters {
    padding-right: 0px;
  }

  .MuiToolbar-root {
    display: flex;
    position: relative;
    align-items: flex-end;
  }

  .MuiToolbar-regular {
    min-height: 0px;
  }

  .clickArrow {
    display: flex;
    padding: 10px;
    button {
      width: 50px;
      height: 28px;
      border-radius: 7px;
      border: 1px solid #00000080;
      box-shadow: 0px 1px 4px 0px #00000040;
      display: flex;
      justify-content: center;
      align-items: center;
      background: #ffffff;
      img {
        width: 19px;
        height: 18px;
        opacity: 0.4;
      }
    }
  }

  .webTitle {
    padding: 20px 10px 0px 10px;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;

    img {
      width: 230px;
      height: 50px;
    }

    h1 {
      margin: 0px;
      font-family: Montserrat;
      font-size: 6px;
      font-style: normal;
      font-weight: 500;
      line-height: 10px;
      letter-spacing: 1.98em;
      text-align: center;
      color: #ffffff;
      transform: translateY(-50%);
    }
  }
}
