@use '../../stylesheet/elements';
@use '../../stylesheet/textStyle';
@use '../../stylesheet/paging';

.myLeasePage{
    display: flex;
    flex-wrap: wrap;
    @media screen and (max-width: 640px){
        padding-top: 5rem;
    }
   
    .myLease{
        @include paging.mainPage();

        .mobileButton{
            margin-top: 10px;
            width: 100%;
            display: flex;
            justify-content: center;

            .divButton{
                width: 300px;
                display: none;


                @media screen and (max-width: 640px){
                    display: flex;
                    background: white;
                    border: 1px solid black;
                    border-radius: 10px;
    
                    button{
                        border: none;
                        width: 50%;
                        padding: 5px;
                        border-radius: 9px;
                        background: none;
                        transition: 0.2s ease;
                        font-size: 14px;
                        font-weight: 400;
                        letter-spacing: 0em;
                    }
    
                    .active{
                        background: #C4C4C4;
                        // border: 1px solid rgb(0, 0, 0)
                    }
                }
            }
        }

        .pageContentMobile{
            display: none !important;
            @media screen and (max-width: 640px){
                display: flex !important;
            }
        }

        .pageContent{
            @media screen and (max-width: 640px){
                display: none !important;
            }
        }

        .pageContentMobile,
        .pageContent{
            padding: 1.7rem 0rem;
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            gap: 30px;

            @media screen and (max-width: 640px){
                justify-content: center;
                padding-top: 1.5rem;
            }

            .customerActions{
                display: flex;
                flex-direction: column;
                align-items: center;
                gap: 30px;
                
                .carInfo{
                    width: 300px;
                    background: linear-gradient(180deg, #C8C8C8 0%, rgba(206, 206, 205, 0.34) 100%);
                    padding: 1px 8px;
                    border-radius: 10px;
                    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.405);                
                    border: 1px solid rgba(0, 0, 0, 0.213);

                    .title{
                        h1{
                            font-weight: 500;
                            font-size: 18px;
                            margin: 8px 0px 0px 0px;
                        }
                    }

                    hr{
                        border: 1px solid #000000;
                        margin-bottom: 10px;
                        opacity: 0.4;
                    }
                    .image{
                        display: flex;
                        justify-content: center;
                        img{
                            width: 290px;
                            height: 210px;
                            border-radius: 25px;
                        }
                        
                    }

                    .contentInfo{
                        padding: 5px;
                        p{
                            display: grid;
                            grid-template-columns: 45% auto;
                            font-size: 12px;
                            justify-items: start;
                        }
                    }

                    .button{
                        padding: 10px 5px;
                        button{
                            @include elements.buttonC();
                            width: 100%;
                            
                        }
                    }
                }//end of carInfo

                .walletCard{
                    @include elements.infoTile(300px, 0px);

                    .walletContent{
                        display: flex;
                        flex-direction: column;
                        gap: 10px;

                        .desc{
                            font-size: 12px;
                            font-weight: 400;
                            line-height: 15px;
                            text-align: left;
                        }

                        .money{
                            font-size: 22px;
                            font-weight: 600;
                            line-height: 27px;
                            text-align: left;
                            color: #04681A;
                        }

                        .topUp{
                            display: flex;
                            flex-direction: row;
                            gap: 10px;
                            justify-content: space-between;

                            button{
                                @include elements.buttonC();
                                width: 100px;
                            }

                            input{
                                @include elements.inputField();
                                width: 250px;
                                font-family: Montserrat;
                            }
                        }
                    }
                }//end of walletCard

                .yourFiles{
                    @include elements.infoTile(300px, 0px);
                    .buttons{
                        padding-top: 10px;
                        display: flex;
                        flex-direction: column;
                        gap: 10px;

                        button{
                            @include elements.buttonC();
                        }
                    }
                }//end of emailTemplates

                .paymentsSetup{
                    @include elements.infoTile(300px, 0px);

                    .paymentsContent{
                        display: flex;
                        flex-direction: column;
                        gap: 10px;

                        .desc{
                            font-size: 12px;
                            font-weight: 400;
                            line-height: 15px;
                            text-align: left;
                        }

                        button{
                            @include elements.buttonC();
                            width: 100%;
                        }
                    }
                }//end of files
            } //end of CustomerAction

            .customerInfo{
                display: flex;
                flex-direction: column;
                gap: 30px;
                @media screen and (max-width: 640px){
                    width: 100%;
                    align-items: center;
                }
                
                .contactInfo{
                    @include elements.infoTile(500px, 0px);
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;
                    @media screen and (max-width: 640px){
                        width: 300px;
                    }

                    .inputs{
                        display: flex;
                        flex-direction: column;
                        gap: 10px;

                        input{
                            border-radius: 5px;
                            border: 1px solid rgba(0, 0, 0, 0.556);
                            padding: 5px 10px;

                        }
                    }

                    .button{
                        display: flex;
                        flex-direction: row;
                        justify-content: flex-end;
                        align-items: flex-end;
                        margin: 10px 10px 0px 0px;

                        button{
                            @include elements.buttonC();
                            width: 100px;
                            padding: 10px;
                        }
                    }
                } //end of ContactInfo

                .transactionHistory{
                    @include elements.infoTile(500px, 0px);
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;
                    @media screen and (max-width: 640px){
                        width: 300px;
                    }

                    .contentInfo{
                        display: flex;
                        flex-direction: column;
                        gap: 10px;

                        .contentCard{
                            display: grid;
                            grid-template-columns: 20% 65% 15%;
                            grid-template-areas: "date title price";
                            justify-content: space-between;
                            border: 1px solid #000000;
                            padding: 7px;
                            border-radius: 7px;
                            align-items: center;
                            @media screen and (max-width: 640px){
                                padding: 10px;
                                grid-template-columns: auto auto auto;
                                grid-template-areas: "date date date"
                                                    "title title title"
                                                    "price price price";
                                gap: 1px;
                                align-items: flex-start;
                            }
        
                            .date{
                                grid-area: date;
                                font-weight: 400;
                                font-size: 12px;
                            }
        
                            .name{
                                grid-area: title;
                                font-weight: 400;
                                font-size: 12px;
                                font-weight: 600;
                            }
        
                            .price{
                                grid-area: price;
                                font-weight: 600;
                                font-size: 12px;
                                justify-self: end;
                                color: #702632;
                                @media screen and (max-width: 640px){
                                    justify-self: end;
                                }
                            }
                        }
                    }

                    .buttons{
                        display: flex;
                        flex-direction: row;
                        justify-content: flex-end;
                        margin-top: 20px;

                        img{
                            width: 50px;
                            height: 50px;
                        }

                        button{
                            background: none;
                            border: none;
                        }
                    }
                }//end of transactionHistory

                .paymentTracker{
                    @include elements.infoTile(500px, 0px);             
                    @media screen and (max-width: 640px){
                        width: 300px;
                    }

                    .info{
                        display: flex;
                        align-items: center;
                        flex-wrap: wrap;
                        justify-content: center;
                        gap: 5px;
                        margin-top: 10px;
                        p{
                            text-align: center;
                            font-size: 12px;
                            font-weight: 400;
                            line-height: 15px;
                            letter-spacing: -0.06em;
                            margin: 0px;
                            gap: 0px;
                        }
                    }
                    

                    .trackerTable{
                        overflow-x: auto;
                        padding: 10px 5px;

                        &::-webkit-scrollbar{
                            /* Hide scrollbar for Chrome, Safari and Opera */
                            display: none;
                            /* Hide scrollbar for IE, Edge and Firefox */
                            -ms-overflow-style: none;  /* IE and Edge */
                            scrollbar-width: none;  /* Firefox */
                        }

                        table{
                            border-collapse: collapse;
                            @media screen and (max-width: 640px){
                                width: 300px;
                                overflow-x: scroll;
                                &::-webkit-scrollbar{
                                    /* Hide scrollbar for Chrome, Safari and Opera */
                                display: none;
                                /* Hide scrollbar for IE, Edge and Firefox */
                                -ms-overflow-style: none;  /* IE and Edge */
                                scrollbar-width: none;  /* Firefox */
                                }
                            }

                            .months{
                                th{
                                    background: #CCCCCC;
                                    font-size: 12px;
                                    font-weight: 500;
                                }

                                .empty{
                                    border: none;
                                    background: white;
                                }
                            }

                            th{
                                width: 100px;
                                height: 40px;
                                border: 1px solid rgb(0, 0, 0);
                                padding: 0px 20px;
                                font-size: 11px;
                                font-weight: 400;
                            }

                            .category{
                                background: #CCCCCC;
                                font-size: 12px;
                                font-weight: 500;
                            }
                        }
                        
                    }
                }

                .customerAsset{
                    @include elements.infoTile(500px, 0px);
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;

                    @media screen and (max-width: 640px){
                        width: 300px;
                    }

                    .contentInfo{
                        display: flex;
                        flex-direction: column;
                        gap: 10px;
                        padding-bottom: 10px;

                        .Active{
                            border: 2px solid #702632 !important; 
                            background: white !important;
                        }

                        .assetCard{
                            display: flex;
                            flex-direction: row;
                            justify-content: space-between;
                            align-items: center;
                            background: #EEEEEE;
                            height: 30px;
                            padding: 5px 10px;
                            border: 1px solid #000000;
                            border-radius: 7.5px;
                            font-size: 12px;
                            font-weight: 400;

                            .status{
                                font-weight: 600;
                                color: #702632;
                            }
                        }
                    }
                }//end of customerAsset
            }//end of customerInfo
    }
    
    }
}