@use './elements';
@use './textStyle';


/* Template for MainPage padding, link decoration, and pagetitle */
@mixin mainPage {
    padding: 10px 50px;

    a{
        text-decoration: none !important;
        color: black !important;
    }

    @media screen and (max-width: 640px){
        width: 100%;
        padding: 10px;
    }

    .pageTitle{
        p{
            font-weight: 400;
            font-size: 12px;
            margin-top: 5px;
            margin-bottom: 0px;
        }

        .pageHeaderWeb,
        .pageHeaderMobile{
            @include textStyle.pageHeader();
        }

        .pageHeaderWeb{
            @media screen and (max-width: 640px){
                display: none;
            }
        }

        .pageHeaderMobile{
            display: none;
            @media screen and (max-width: 640px){
                display: block;
            }
        }

    }
}

/* PageContent of MainPage */
@mixin mainPageContent{
    padding-top: 1.5rem;
    width: 50vw;

    @media screen and (max-width: 640px){
        width: 100%;
        padding-top: 0.5rem;
    }
}

