.loginPage{
    a{
        text-decoration: none;
        color: black;
    }
    .topBar{
        overflow: hidden;
        position: fixed; /* Set the navbar to fixed position */
        top: 0; /* Position the navbar at the top of the page */
        width: 100%; /* Full width */

        height: 180px;
        background: url('../../assets/loginTopBackground.png'), #00000041;
        background-repeat: no-repeat;
        background-position: 0px -150px;
        background-blend-mode: overlay;
        padding-top: 15px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        @media screen and (max-width: 640px){
            background: url('../../assets/loginTopBackground.png'), #00000096;
            background-size: 400px 500px;
            background-position: 0px -50px;
            justify-content: flex-end;
            height: 130px;
        }
        
        img{
            height: 160px;
            @media screen and (max-width: 640px){
                width: 320px;
                height: 75px;
            }
        }

        h1{
            margin: 0px;
            font-family: Montserrat;
            font-size: 20px;
            font-style: normal;
            font-weight: 600;
            line-height: 35px;
            letter-spacing: 1.98em;
            text-align: center;
            color: #FFFFFF;
            transform: translateY(-50%);
            @media screen and (max-width: 640px){
                font-size: 10px;
            }
        }
    }

    .loginContent{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 30px;
        padding: 13rem 1rem 1rem 1rem;
        min-height: 90vh;

        @media screen and (max-width: 640px){
            padding: 10rem 1rem 1rem 1rem;
        }

        .loginTitle{
            font-size: 48px;
            font-weight: 700;
            line-height: 59px;
            letter-spacing: -0.06em;
            text-align: center;
        }

        .loginCard{
            height: 632px;
            width: 400px;
            border-radius: 20px;
            border: 1px solid #000000;
            font-size: 64px;
            font-weight: 600;
            line-height: 78px;
            letter-spacing: -0.06em;
            text-align: center;
            display: flex;
            align-items: center;
            justify-content: center;
            background: #C4C4C4;

            @media screen and (max-width: 640px){
                width: 300px;
            }

        }

        .loginHelp{
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            gap: 10px;

            .help1{
                font-size: 22px;
                font-weight: 600;
                line-height: 27px;
                letter-spacing: -0.06em;
                text-align: center;
            }

            .help2{
                font-size: 18px;
                font-weight: 500;
                line-height: 22px;
                letter-spacing: -0.06em;
                text-align: center;
            }
        }
    }
}