@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@600&display=swap');


*{
  text-decoration: none;
  font-family: 'Montserrat', sans-serif;
  -webkit-font-smoothing: antialiased !important;
  -webkit-appearance: none!important;
}
.App {
  background: #F4F3F2; 
  letter-spacing: -0.06em;
}

button{
  font-family: Montserrat;
  letter-spacing: -0.06em;
}


input::placeholder{
  font-family: Montserrat;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 15px;
  letter-spacing: -0.06em;
  text-align: left;
  color: #000000;
  opacity: 0.6;
}


.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
