/******** Start: ACTIVECORE TAB SECTION ********/
@mixin activeCoreTab{
    background: linear-gradient(270deg, #ffffff75 0%, #4f4e4e75 73.38%, #ffffff85 104.25%);
    height: 60px;
}

@mixin userTile{
    width: 230px;
    height: 67px;
    border-radius: 10px;
    background: #888888b4;
    border: 0.25px solid #00000020;
    box-shadow: 0px 4px 4px -3px #00000040;
}

/******** End: ACTIVECORE TAB SECTION ********/

/******* start: INPUT EMELEMENTS *********/
@mixin inputField{
    height: 30px;
    border: 1px solid #8F8F8F;
    box-sizing: border-box;
    border-radius: 5px;
    transition:all 0.2s ease-in-out;
    padding: 0px 10px;
    &:hover{
        box-shadow: 0px 0px 4px 3px rgba(0, 0, 0, 0.15);
    }
    &:focus {
        box-shadow: 0px 0px 4px 3px rgba(0, 0, 0, 0.25);
    }
}

@mixin filterTile{
    height: 45px;
    background: #AAAAAA;
    border-radius: 10px;
    color: #FFFFFF;
    flex-direction: column;
    justify-content: center;
    margin: 10px 0px;
    padding: 5px;
    display: flex;

    .top{
        font-size: 13px;
        font-weight: 400;
        color: #FFFFFF;
        opacity: 0.89;
        margin-left: 10px;
    
    }

    .bottom{
        display: flex;
        justify-content: space-between;
        button{
            background: none;
            border: none;
            font-size: 18px;
            color: #FFFFFF;
        }
    }
}


@mixin inputWithCopy{
    display: grid;
    width: 100%;
    grid-template-columns: auto 8%;
    justify-content: stretch;  
    gap: 5px;
    @media screen and (max-width: 640px){
        grid-template-columns: auto 15%;
    }

    button{
        height: 30px;
        border: 1px solid #8F8F8F;
        border-radius: 5px;
        transition:all 0.2s ease-in-out;
        color: rgba(0, 0, 0, 0.357);
        font-size: 10px;
        background: none;
        display: flex;
        justify-content: center;
        align-items: center;
        transition:all 0.2s ease-in-out;
        &:hover{
            box-shadow: 0px 0px 4px 3px rgba(0, 0, 0, 0.15);
        }
        &:active{
            box-shadow: inset 0px 4px 5px rgba(0, 0, 0, 0.2);
        }
    }
}


@mixin rockerCombo{
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .enterAmount{
        display: flex;
        justify-content: center;
        gap: 10px;
        .amountInfo{
            /* Chrome, Safari, Edge, Opera */
            input::-webkit-outer-spin-button,
            input::-webkit-inner-spin-button {
            -webkit-appearance: none;
            margin: 0;
            }

            /* Firefox */
            input[type=number] {
            -moz-appearance: textfield;
            }
             input{
                width: 120px;
                height: 60px;
                border: 1px solid #8F8F8F;
                border-radius: 5px;
                display: flex;
                justify-content: center;
                align-items: center;
                color: #8F8F8F;
                font-size: 12px;
                text-align: center;
            }
        }

        .buttons{
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            height: 60px;

            button{
                border-radius: 50%;
                height: 26px;
                width: 26px;
                border: 1px solid #8F8F8F;
                font-size: 25px;
                display: flex;
                justify-content: center;
                align-items: center;
                color: #8F8F8F;
                background: none;
            }
        }
    }


    form{
        width: 100%;
    }
    .submitButton{
        padding-top: 10px;
        width: 100%;
        button{
            @include buttonA();
            width: 100%;

        }
    }

    
    
}

/******* end: INPUT EMELEMENTS *********/

/******* start: BUTTONS ************/
@mixin buttonA{
    height: 30px;
    background: #702632;
    border: 0.5px solid #000000;
    box-sizing: border-box;
    border-radius: 5px;
    padding: 10px 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #FFFFFF;
    transition:all 0.2s ease-in-out;
    &:hover{
        box-shadow: 0px 0px 4px 3px rgba(0, 0, 0, 0.118);
    }

    &:active{
        box-shadow: inset 0px 4px 5px rgba(0, 0, 0, 0.2);
    }

}


@mixin buttonC{
    height: 30px;
    background: #EEEEEE;
    border: 1px solid #0000002c;
    box-sizing: border-box;
    border-radius: 5px;
    padding: 10px 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    transition:all 0.2s ease-in-out;
    &:hover{
        box-shadow: 0px 0px 4px 3px rgba(0, 0, 0, 0.118);
    }

    &:active{
        box-shadow: inset 0px 4px 5px rgba(0, 0, 0, 0.2);
    }
}

/******* end: BUTTONS ************/



@mixin dataEntry{
    height: 50px;
    border: 1px solid #000000;
    box-sizing: border-box;
    border-radius: 7px;
    padding: 10px;
    background: #EEEEEE;

    @media screen and (max-width: 640px){
        height: auto;
    }
}

@mixin infoTile($width, $margin){
    background: #FFFFFF;
    border: 0.5px solid #8f8f8f6e;
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.4);
    border-radius: 25px;
    padding: 5px 15px 20px 15px;
    transition:all 0.2s ease-in-out;
    margin: $margin;
    width: $width;

    .title{
        margin-bottom: 10px;
        h1{
            font-size: 18px;
            font-weight: 500;
        }
    }
}
